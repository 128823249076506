<template>
  <div class="login">
    <div class="form-wrapper">
      <div class="logo">
        <img src="../assets/img/logo-crm-abondance.png" alt="" />
      </div>
      <p>
        Veuillez vous connecter pour continuer.
      </p>
      <form @submit.prevent="onSubmit">
        <div class="input-wrapper">
          <label for="email">E-mail</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="input-wrapper">
          <label for="password">Mot de passe</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <div class="submit-wrapper">
          <v-button type="submit" :disabled="processing">
            CONNEXION
            <v-progress-circular
              v-if="processing"
              indeterminate
              color="white"
              class="sz-loader-icon ml-2"
            ></v-progress-circular>
          </v-button>
        </div>
        <div v-if="loginError" class="error-msg">
          {{ loginError }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/ui/Button.vue'

export default {
  name: 'Login',
  components: {
    'v-button': Button
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'loginError', 'processing'])
  },
  methods: {
    ...mapActions(['login']),
    onSubmit() {
      const data = {
        email: this.email,
        password: this.password
      }
      this.login(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form-wrapper {
    position: relative;
    width: 400px;
    border: 1px solid #d8d8d8;
    padding: 30px 20px;
    box-shadow: 0 2px 3px #e7e7e7;
    min-height: 300px;
    background: #fff;

    p {
      text-align: center;
      font-weight: 700;
    }

    .logo {
      text-align: center;
      img {
        max-width: 150px;
      }
    }

    form {
      position: relative;
      .input-wrapper {
        margin: 10px auto;
        label {
          display: block;
          color: #4e4e4e;
          margin-bottom: 6px;
          font-size: small;
        }
        input {
          font: inherit;
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #ddd;
          &:focus {
            outline: none;
            border: 1px solid #1184b0;
            background-color: #eee;
          }
        }
      }
      .submit-wrapper {
        margin: 20px 0;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          .sprocessing-pinner {
            margin-left: 10px;
          }
        }
      }

      .error {
        position: absolute;
        width: 100%;
        bottom: -30px;
        color: red;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>
